import React from 'react';
import { ReactComponent as UnderdogLogo } from '../../assets/images/underdog-logo.svg';
import { ReactComponent as StarIcon } from '../../assets/images/star-icon.svg';

const MenuIcons = {
  home: <UnderdogLogo />,
  search: <StarIcon />,
  profile: <StarIcon />,
};

export default MenuIcons;
